<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON SERVİS
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON SERVİS" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.bm_firmaId" ref="entity_firma"
									label="Firma" entityName="account" nameField="name" :state="true" :required="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_firmaId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_firmaId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_servistarihi">Servis Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_servistarihi" v-model="mainData.bm_servistarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_servistarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_servistarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-6">
							<div class="field p-fluid">
								<label for="ekip">Ekip</label>
								<MultiSelect v-model="selectedMobilKullanicilar" :options="mobilKullanicilarData" optionLabel="name" placeholder="Ekip seçiniz" :filter="true" class="multiselect-custom">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Ekip seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="baslik">Başlık <span style="color:red">*</span></label>
								<InputText id="baslik" type="text" v-model="mainData.bm_name" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="bm_yapilanlar">Yapılanlar</label>
								<Textarea id="bm_yapilanlar" rows="4" :autoResize="false" v-model="mainData.bm_yapilanlar" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10062,
			ENTITY_NAME: 'bm_otomasyonservis',
			ENTITY_SUBJECT: 'Otomasyon Servis',
			mainData: {},

			mobilKullanicilarData: [],
			selectedMobilKullanicilar: null,

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		let mobilKullaniciDataTemp = await this.crmService.GetMobilKullanicilarOtomasyon();
		debugger;
		if (mobilKullaniciDataTemp != null) {
			this.mobilKullanicilarData = mobilKullaniciDataTemp.jsonData;
		}
    },
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["bm_firmaId"]) {
					return { "Value": this.mainData["bm_firmaId"], "Name": this.mainData["bm_firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_firmaId"] = null;
					this.mainData["bm_firmaName"] = null;
				}
				else {
					this.mainData["bm_firmaId"] = newValue.Value;
					this.mainData["bm_firmaName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedMobilKullanicilar) {
						if (this.selectedMobilKullanicilar.length > 0) {
							this.mainData["ekip"] = this.selectedMobilKullanicilar;
						}
					}

					const response = await this.crmService.getOtomasyonServisCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: this.ENTITY_NAME, params: { id: response.olusturulanId } });
								}, 2000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Servis");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_name: {
					required : helpers.withMessage('Başlık alanı dolu olmalıdır!', required),
				},
				bm_yapilanlar: {
					required : helpers.withMessage('Yapılanlar alanı dolu olmalıdır!', required),
				},
				bm_firmaId: {
					required : helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
				bm_servistarihi: {
					required : helpers.withMessage('Servis Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.stickBar {
		position: sticky;
		top: 6.2rem;
		z-index: 10;
	}

	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}


</style>
